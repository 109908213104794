import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const pages = [
  {
    name: 'Home',
    link: '/',
    // link: '/home',
    isHash: false
  },
  {
    name: 'About',
    link: '/#about',
    isHash: true
  },
  {
    name: 'Skills',
    link: '/#skills',
    isHash: true
  },
  {
    name: 'Portfolio',
    link: 'https://yvonneubak.com/portfolio',
    target: '_blank',
    isHash: false
  },
  {
    name: 'Services',
    link: '/#services',
    isHash: true
  },
  {
    name: 'Booking',
    link: '/#booking',
    isHash: true
  },
  {
    name: 'Contact Us',
    link: '/#contact',
    isHash: true
  },
  {
    name: 'Policies',
    link: '/policy',
    isHash: false
  }
];

const Navigation = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const defaultTarget = '_self';

  return (
    <>
      <AppBar position="sticky" className="navbar">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="menu icon"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
                className='dropdownMenu'
              >
                {pages.map((page) => (
                  // <a key={page.name} href={page.link} className="navlink dropdownLink" target={page.target ? page.target : defaultTarget}>
                  //   <MenuItem key={page.name} onClick={handleCloseNavMenu} className='dropdownMenuItem'>
                  //     <Typography textAlign="center" className='navContainer'>
                  //       {page.name}
                  //     </Typography>
                  //   </MenuItem>
                  // </a>
                  <MenuItem key={page.name} onClick={handleCloseNavMenu} className='dropdownMenuItem'>
                    {page.isHash ? (
                      <HashLink to={page.link} className="navlink dropdownLink" target={page.target || defaultTarget}>
                        <Typography textAlign="center" className='navContainer'>
                          {page.name}
                        </Typography>
                      </HashLink>
                    ) : (
                      <a href={page.link} className="navlink dropdownLink" target={page.target || defaultTarget}>
                        <Typography textAlign="center" className='navContainer'>
                          {page.name}
                        </Typography>
                      </a>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              {pages.map((page) => (
                page.isHash ? (
                <HashLink key={page.name} smooth to={page.link} className='navlink' target={page.target ? page.target : defaultTarget}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block' }}
                  className='navlinkBtn'
                >
                  {/* <a href={page.link} className="navlink">{page.name}</a> */}
                  {page.name}
                </Button>
                </HashLink>
              ) : (
                <Link key={page.name} to={page.link} className='navlink' target={page.target ? page.target : defaultTarget}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: 'block' }}
                  className='navlinkBtn'
                >
                  {/* <a href={page.link} className="navlink">{page.name}</a> */}
                  {page.name}
                </Button>
                </Link>
              )
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default Navigation;