import { React } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
// import { CheckIcon } from '@mui/icons-material';


export default function Contact() {

        return (
            <div className='contact-container'>
                <div className="contact-page pages">
                    <Link name="contact" id="contact" style={{opacity: "0"}} to='#contact'>
                        <h1 className='hidden-link'>Contact Us</h1>
                    </Link>
                    <div className="title-link">
                        <h1>Contact <span style={{color: 'rgb(236, 144, 109)'}}>{'{'} Us {'}'}</span></h1>
                    </div>
                    <h2>Any Questions? Need Guidance Builing Your Site? <br/> Automation Services?</h2>
                    <section className="section-div">
                        {/* //contact form that sends an email with emailjs */}
                        <ContactForm />
                    </section>
                </div >
            </div>
        )
}