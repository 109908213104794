import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
// import beigeComp from '../../../../assets/images/beige computer.jpg';
import oliveComp from '../../../../assets/images/olive computer.jpg';
import { PopupButton } from 'react-calendly';

export default function Booking() {
    const modalRoot = document.getElementById('root') || document.body;
    // const modalRoot = document.getElementById('homepage');

    return (
        <>
            <div className='pages booking-page' id='modal-root'>
                <section className="section-five section-div">
                    <Grid container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className="booking-row"
                    >
                        <Grid item md={6} sm={6} className='booking'>
                            <Link name="booking" id="booking" style={{opacity: "0", cursor: 'none'}} to='#booking'>
                                <h1 className='hidden-link'>Booking</h1>
                            </Link>
                            <PopupButton className='booking-btn'
                                rootElement={modalRoot}
                                url='https://calendly.com/dashofdara/introductory-consultation'
                                pageSettings={{
                                    backgroundColor: 'ec906d',
                                    primaryColor: 'FFFFFF'
                                }}
                                text= 'Book a consult today!' />
                                <p>Are you a return client?<br/> Contact Me to book a <a href='#contact' style={{color: 'olive'}}>Maintenance Consult here!</a></p>
                        </Grid>
                        <Grid item md={6} sm={6} className='booking-img'>
                            {/* <img src={beigeComp} /> */}
                            <img src={oliveComp} alt='Laptop in front of olive background'/>
                        </Grid>
                    </Grid>
                </section>
            </div>
        </>
    )
}
