import React from 'react'

function PolicyList({policyData, policyCategory}) {
  return (
    <>
        <ul>
            {policyData.map((el, idx) => {
                          if (el.category === policyCategory) {
                            return (
                              <li key={idx}>
                                {el.content}
                                {el.subList ? (
                                  <ul>
                                    {el.subList.map((subEl, i) => (
                                      <li key={i}>{subEl}</li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            );
                          }
                          return null; 
                        })}
                      </ul>
    </>
  )
}

export default PolicyList