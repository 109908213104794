import React from 'react';
import centerSucc from '../../assets/images/center succ cropped.jpg';
import { policyCategory } from '../../projectData/data';
import PolicyList from './PolicyList';
import PrivacyPolicy from './PrivacyPolicy';

export default function Policy({policyData}) {
    return (
        <>
            <div className='pages policy-page'>
                <div className='title-container'>
                    <img src={centerSucc} loading='lazy' alt='Close up of green succulent leaves'/>
                    <h1>Policies</h1>
                </div>
                <section className="section-six section-div">
                    <div className='policy-class'>
                        <h2>Booking Policy</h2>
                        <PolicyList policyData={policyData} policyCategory={policyCategory.BOOKING} />
                    </div>
                    <div className='policy-class'>
                        <h2>Cancellation Policy</h2>
                        <PolicyList policyData={policyData} policyCategory={policyCategory.CANCELLATION} />
                    </div>
                    <div className='policy-class'>
                        <h2>Deposit Policy</h2>
                        <PolicyList policyData={policyData} policyCategory={policyCategory.DEPOSITS} />
                    </div>
                    <div className='policy-class'>
                        <h2>Privacy Policy</h2>
                        <PrivacyPolicy />
                    </div>
                </section>
            </div>
        </>
    )
}
