import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import TerminalIcon from '@mui/icons-material/Terminal';

export class Skills extends Component {
    render() {
        return (
            <div className='skill-container'>
                <div className="skill-page pages">
                    <Link name="skills" id="skills" style={{opacity: "0"}} to='#skills'>
                        <h1 className='hidden-link'>Problem Solver</h1>
                    </Link>
                    <div className="title-link">
                        <h1>Problem <span style={{color: 'rgb(236, 144, 109)'}}>{'{'}Solver{'}'}</span></h1>
                    </div>
                    <h2>Full Stack Web Developer with over 7 years of experience</h2>
                    <section className="section-two section-div">
                        <div className='skill-container'>
                            <Grid container
                                spacing={3}
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                                className="row-one">
                                <Grid item className="grid-item" sm={3}>
                                    <div className='skills-list-item'>
                                        <TerminalIcon />
                                        <p><span style={{fontWeight: '600'}}>Bachelors of Science in Computer Information Systems</span> from the University of Houston.</p>
                                    </div>
                                </Grid>
                                <Grid item className="grid-item" sm={3}>
                                    <div className='skills-list-item'>
                                        <TerminalIcon />
                                        <p><span style={{fontWeight: '600'}}>Specializes in ReactJS, Wordpress, Functional Programming, and RDBMS.</span> I am an avid learner that is up to a challenge and always picking up new technologies.</p>
                                    </div>
                                </Grid>
                                <Grid item className="grid-item" sm={3}>
                                    <div className='skills-list-item'>
                                        <TerminalIcon />
                                        <p><span style={{fontWeight: '600'}}>Collaborator.</span> I collaborate with other professionals to provide the best product to my clients.</p>
                                    </div>
                                </Grid>
                                <Grid item className="grid-item" sm={3}>
                                    <div className='skills-list-item'>
                                        <TerminalIcon />
                                        <p><span style={{fontWeight: '600'}}>Mentor.</span> I provide mentorship and training to New and Junior developers.</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </div >
            </div>
        )
    }
}

export default Skills
