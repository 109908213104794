import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Grid, TextField, Button, Alert, Snackbar } from '@mui/material';
import { useForm, Form, Dropdown } from '../../../constants/useForm';
import { serviceOptions } from '../../../constants/Enums';

export default function ContactForm() {
    let intialFormValues = {
        id: 0,
        user_firstName: '',
        user_lastName: '',
        user_brand: '',
        user_email: '',
        user_service: 'None',
        message: '',
        timeStamp: new Date()
    }

    const {values, setValues, handleInputChange} = useForm(intialFormValues);
    const [open, setOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMsg, setAlertMsg] = useState('');

    const handleClose = (event, eventType) => {
        if(eventType === 'clickaway'){
            return;
        }
        setOpen(false);
    }

    const formRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_j0jf4q9', 'template_mk44t3t', formRef.current, {
            publicKey: 'yUDlfXJfe8IhNkV3j',
        })
        .then(
            (result) => {
                console.log(result.text);
                console.log('Message Sent');
                
                //reset values
                setValues({...intialFormValues});

                //set alert that message has been sent
                setAlertSeverity('success');
                setAlertMsg('Submission Successful! I will be in touch with you shortly!');
                setOpen(true);
            },
            (error) => {
                console.log('FAILED...', error.text);
                setAlertSeverity('warning');
                setAlertMsg('Failed Submission. Please try again.');
                setOpen(true);
            },
        );
    };

  return (
    <>
    <Form onSubmit={sendEmail} ref={formRef}>
        <Grid container direction='row' spacing={2} className='form-grid'>
            <Grid item xs={6}>
                <TextField variant='standard'
                            label='First Name'
                            name='user_firstName'
                            value={values.user_firstName}
                            onChange={handleInputChange}
                            required />
            </Grid>
            <Grid item xs={6}>
                <TextField variant='standard'
                            label='Last Name'
                            name='user_lastName'
                            value={values.user_lastName}
                            onChange={handleInputChange}
                            required />
            </Grid>
        </Grid>
        <Grid container direction='row' spacing={2} className='form-grid'>
            <Grid item xs={6}>
                <TextField variant='standard'
                            label='Business/Brand Name'
                            name='user_brand'
                            value={values.user_brand}
                            onChange={handleInputChange}
                            required />
            </Grid>
            <Grid item xs={6}>
                <TextField variant='standard'
                            label='Email'
                            name='user_email'
                            value={values.user_email}
                            onChange={handleInputChange}
                            required />
            </Grid>
        </Grid>
        <Grid container direction='row' spacing={2}className='form-grid'>
            <Grid item xs={12}>
                <Dropdown label='Service/Package'
                name='user_service'
                value={values.user_service}
                onChange={handleInputChange}
                options={serviceOptions} />
            </Grid>
        </Grid>
        <Grid container direction='row' className='form-grid'>
            <Grid item xs={12}>
                <TextField variant='outlined'
                            multiline
                            required
                            maxRows={5}
                            minRows={3}
                            name='message'
                            label='Message'
                            value={values.message}
                            onChange={handleInputChange}
                            fullWidth/>
            </Grid>
        </Grid>
        <Grid container direction='row' className='form-grid'>
            <Grid item xs={12}>
                <Button variant='outlined'
                        className='form-btn'
                        type='submit'>
                    Send
                </Button>
            </Grid>
        </Grid>
    </Form>

    {/* // this message will pop up on the screen based on a condition */}
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert severity={alertSeverity} onClose={handleClose} sx={{width:'100%'}}>
            {alertMsg}
        </Alert>
    </Snackbar>
    <a href='/policy' style={{color: 'black', fontWeight: 'bold'}}><p>View Dash of Dara Policies before our meeting or session.</p></a>
    </>
  )
}
