const serviceOptions = [
    {
        id: 0,
        title: 'Introductory Consultation',
        value: 'Introductory Consultation'
    },
    {
        id: 1,
        title: 'Web Development Package',
        value: 'Web Development Package',
    },
    {
        id: 2,
        title: 'Enterprise Package',
        value: 'Enterprise Package',
    },
    {
        id: 3,
        title: 'Workflow Automation Planning',
        value: 'Workflow Automation Planning',
    },
    {
        id: 4,
        title: 'Workflow Automation',
        value: 'Workflow Automation',
    },
    {
        id: 5,
        title: 'Website and Workflow Maintenance',
        value: 'Website and Workflow Maintenance',
    },
    {
        id: 6,
        title: 'DIY Bob da Builder Package',
        value: 'DIY Bob da Builder Package',
    },
    {
        id: 7,
        title: 'Tutoring',
        value: 'Tutoring',
    },
    {
        id: 8,
        title: 'Website Enhancement',
        value: 'Website Enhancement'
    },
    {
        id: 9,
        title: 'Dash of The Woo Combo Package',
        value: 'Dash of the Woo Combo Package'
    }
]

export {
    serviceOptions
}