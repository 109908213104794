import React from 'react';
import introBG from '../assets/videos/introBGgif.gif';
// import { HashLink } from 'react-router-hash-link';

export default function Intro() {
    return (
        <>
            <section className='intro-section'>
                <img src={introBG} loading='lazy' alt="A laptop on a white desk with a green plant moving in the background" />
                {/* <h1 id='title-tag'>Your Vision</h1>
                <h1 id='header-name'>Our Commitment</h1> */}
                {/* <HashLink smooth to="#booking" className='introBtn'><p>Let's Talk</p></HashLink> */}
            </section>
        </>
    )
}
