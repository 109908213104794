import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, {useState, forwardRef} from 'react'

export function useForm(intialFormValues) {
    const [values, setValues] = useState(intialFormValues);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    return {
        values,
        setValues,
        handleInputChange
    }
}

export const Form = forwardRef((props, ref) => {
    return(
        <form className='dash-form' onSubmit={props.onSubmit} ref={ref}>
            {props.children}
        </form>
    )
})

export function Dropdown(props) {
    const {label, options, onChange, name, value, size} = props;
    return(
        <FormControl variant='outlined' fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select label={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                    size={size || 'large'}
                    fullWidth>
                    <MenuItem value='None' key='none'>None</MenuItem>
                {
                    options.map((item, idx) => (
                        <MenuItem value={item.value} key={idx}>{item.title}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}
