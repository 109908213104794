import * as media from './media';

const projects = [
    {
        id: 1,
        title: '$mart Money Manager',
        pic: media.expense,
        description: 'A web expense tracker and budget tool that was created with Angular 8, CSS, Rest, and Spring Boot',
        link: 'https://youtu.be/rIB13iIHTXk'
    },
    {
        id: 2,
        title: 'Dash of Dara',
        pic: media.dash,
        description: 'A dynamic blog that focuses on plant-based recipes and travel tips and experiences. This point of concept was created using ReactJS, Redux/Saga, Sass, Google Charts and Firebase.',
        link: 'https://youtu.be/HtAwxvoMIpY'
    },
    {
        id: 3,
        title: 'Github',
        pic: media.github,
        description: 'This is a link to my Github that showcases code for point-of-concepts and projects I have worked on.',
        link: 'https://github.com/iyubakof'
    },
    {
        id: 4,
        title: 'Corporate Site UI',
        pic: media.corporate,
        description: 'A UI mockup for a corporate training website',
        link: ''
    },
    {
        id: 5,
        title: 'Sunset ToDo App',
        pic: media.sunset,
        description: 'A dynamic web application using Spring Boot, JSP, JSTL, and Bootstrap.',
        link: 'https://www.linkedin.com/posts/yvonneubak_dailyui-frontend-ui-activity-6574481316841086977-v966'
    },
    {
        id: 6,
        title: 'WorldWide Tai Blog',
        pic: media.tai,
        description: 'A Wordpress Blog about Travel and Astrology that was completely revamped and customized to the taste of the owner.',
        link: 'https://worldwidetai.blog/'
    },
    {
        id: 7,
        title: 'Calender Mobile UI',
        pic: media.calenderUI,
        description: 'A UI mockup for a mobile app created in Adobe XD.',
        link: '',
        height: 'auto',
        width: "70%"
    },
    {
        id: 8,
        title: 'trGrace Consulting',
        pic: media.trGrace,
        description: 'A web app that provides development courses and consulting services. This is a Headless CMS utilizing a Wordpress Backend and ReactJS Frontend. The website is still in progress.',
        link: 'https://trgraceconsulting.com'
    },
    {
        id: 9,
        title: 'Novaa Network',
        pic: media.novaa,
        description: 'A membership website, that is now archived, that provided metaphsyical and small business courses and events. This wesbite was built with Squarespace Developer and utilized JavaScript, HTML, CSS, and API calls to communicate with third party plugins.',
        link: 'https://novaanetwork.com'
    },
    {
        id: 10,
        title: 'Qing Oracle',
        pic: media.qing,
        description: `A website created for a Kemet practitioner that provides spiritual readings and guidance. The site was built with Squarespace and utilized HTML, JS, and CSS to meet the customer's. A third party booking plugin was configured and integrated.`,
        link: 'https://www.qingoracle.com/'
    },
];

const policyCategory = Object.freeze({
    BOOKING: "Booking",
    CANCELLATION: "Cancellation",
    DEPOSITS: "Deposits",
});

const policies = [
    {
        id: 0,
        category: policyCategory.BOOKING,
        content: 'Introductory Consultations MUST be booked before receiving any services. During this consultation we will discuss the problem(s) you are facing and possible solutions to meet your needs.',
        subList: ['This consultation fee is non-refundable and will be applied towards the total balance of your services.']
    },
    {
        id: 1,
        category: policyCategory.BOOKING,
        content: 'Workflow and Website Maintenance are for Return Clients ONLY. If you are a new client and in need of upgrading or fixing an existing website/workflow, you MUST book an Introductory Consultation.'
    },
    {
        id: 2,
        category: policyCategory.BOOKING,
        content: 'In the case of changes in schedule or project requirements, this must be done as soon as possible and in a timely fashion.'
    },
    {
        id: 3,
        category: policyCategory.BOOKING,
        content: 'You are free to reschedule appointments up to 1 time, as long as it is 24 hours prior to the appointment. After this, any reschedules will be considered a cancellation, and you will then be required to completely rebook.'
    },
    {
        id: 4,
        category: policyCategory.BOOKING,
        content: 'Any requests to reschedule less than 24 hours prior will be considered a cancellation.'
    },
    {
        id: 5,
        category: policyCategory.CANCELLATION,
        content: 'Cancellations at least 48 hours prior are subject to refunds minus any non-refundable fees or deposits.',
        subList: ['For example, if you book and pay for 3 DIY “Bob da Builder” sessions and cancel the last session more than 48 hours before the appointment, you will be refunded for that last session minus the 50% deposit.']
    },
    {
        id: 6,
        category: policyCategory.CANCELLATION,
        content: 'If you know that you will not make the appointment, I kindly ask that you cancel or reschedule so the timeslot can be made available to other clients.',
    },
    {
        id: 7,
        category: policyCategory.CANCELLATION,
        content: 'Any cancellations must rebook the service and pay any associated fees.',
    },
    {
        id: 8,
        category: policyCategory.CANCELLATION,
        content: 'Late cancellations and No Shows forfeit any fees paid for the service.',
    },
    {
        id: 9,
        category: policyCategory.DEPOSITS,
        content: 'Deposits are non-refundable fees to secure any service performed by Dash of Dara.',
    },
    {
        id: 10,
        category: policyCategory.DEPOSITS,
        content: 'The final product will not be released until all balances are paid in FULL.',
    },
    {
        id: 11,
        category: policyCategory.DEPOSITS,
        content: 'You agree that all work, ideas, and content produced by Dash of Dara is intellectual property that you are purchasing rights to. This work is to be considered property of Dash of Dara until any and all balances are paid in full.',
    },
    {
        id: 12,
        category: policyCategory.DEPOSITS,
        content: 'The booked session will not be administered until the balance for that session is paid in full.',
        subList: ['You have until 24 hours prior to the session to pay any and all balances unless we have previously set out different terms and conditions.']
    }
]

export {
    projects,
    policies,
    policyCategory
}