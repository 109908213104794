import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { LocalFlorist, LocalFloristOutlined, EmojiNature } from '@mui/icons-material';
import threeCacti from '../../../../assets/images/three cacti cropped.jpg';
import cactus from '../../../../assets/images/right cactus.jpg';
import { HashLink } from 'react-router-hash-link';
import serviceData from '../../../../constants/services.json';

export default function Services() {
    let devPackages = serviceData.filter((pckg) => {
        return pckg.type === "website" && !pckg.description;
    });

    let smallPackages = serviceData.filter((pckg) => {
        return pckg.type === "website" && pckg.description;
    });

    let techPackages = serviceData.filter((pckg) => {
        return pckg.type === "tech";
    });

    return (
        <>
            <div className="service-page Landing Page">
                <Link name="services" id="services" style={{opacity: "0"}} to="#services">
                    <h1 className='hidden-link'>Services</h1>
                </Link>
                <hr />
                <div className="title-link">
                    <h1>Services</h1>
                </div>
                <h3 className='tagline'>Websites | Process Automation</h3>
                <section className="section-four section-div">
                    <div className='consult-container'>
                        <img src={threeCacti} alt='Three green cacti in front of awhite background.'/>
                        <Grid container
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className="consult-row"
                        >
                            <Grid item md={12} sm={12} className='consult'>
                                <h3>Introductory Consultation</h3>
                                <small>$50</small>
                                <p>A 30 minute session to discuss your technology plans and needs for your business.</p>
                                <p>Consultation fee will go towards a deposit if you decide to proceed with a service.</p>
                                <p><strong><em>Consult is required before booking any other service.</em></strong></p>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='srv-container' id='web-dev-pckg'>
                        <h2 className='pckg-title'>Web <span style={{color: 'rgb(236, 144, 109)'}}>{'{'} Development {'}'}</span> Packages</h2>
                        <p>Grow Your Leads, Sales, Traffic, and Revenue!</p>
                        <br/>
                        <Grid container
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className="service-packages"
                        >
                            {devPackages.map((serv, idx) => {
                                return(
                                <>
                                    <Grid item md={6} sm={6} className='package' key={idx}>
                                        <HashLink smooth to='#booking'>
                                                <div className='grid-txt'>
                                                    <h2>{serv.name}</h2>
                                                    <h4>{serv.tagline}</h4>
                                                    <small>{serv.small}</small>
                                                    <p>Includes:</p>
                                                    <ul>
                                                        {serv.features.map((feat, x) => {
                                                            return <li key={x}><LocalFloristOutlined sx={{ fontSize: 15 }}/>{feat}</li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </HashLink>
                                    </Grid>
                                </>)
                            })}
                            {smallPackages.map((smPkg, x) => {
                                return(
                                    <>
                                        <Grid item md={6} sm={6} className='package' key={x}>
                                            <HashLink smooth to='#booking'>
                                                <div className='grid-txt'>
                                                    <h2>{smPkg.name}</h2>
                                                    <h4>{smPkg.tagline}</h4>
                                                    <p className='desc'>{smPkg.description}</p>
                                                </div>
                                            </HashLink>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    </div>

                    <div className='addon-container'>
                        <br />
                        <br />
                        <img src={cactus} alt='Cactus with an orange-red flower on a white background.'/>
                        <Grid container
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className="addons"
                        >
                            <Grid item md={12} sm={12} lg={12} xs={12} className='addon-txt'>
                                <h2>Additional Features</h2>
                                <p>Package Addons</p>
                                <ul>
                                    <li><EmojiNature/>Additional pages and forms</li>
                                    <li><EmojiNature/>Standard E-commerce Integration</li>
                                    <li><EmojiNature/>Basic or Advanced SEO</li>
                                    <li><EmojiNature/>Modernized Style</li>
                                    <li><EmojiNature/>Responsive Features</li>
                                    <li><EmojiNature/>Third Party Tool Integration</li>
                                    <li><EmojiNature/>Domain and Web Hosting</li>
                                </ul>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='srv-container' id='automation-serv'>
                        <h2 className='improv-title'>Tech Enhancements and Automation</h2>
                        <p>Streamline, Automate, and Digitially Transform Your Business</p>
                        <Grid container
                            spacing={3}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className="tech-improvements"
                        >
                            {techPackages.map((pkg, idx) => {
                                return(
                                    <Grid item md={6} sm={6} className='improvement' key={idx}>
                                        <HashLink smooth to='#booking'>
                                            <div className='grid-txt'>
                                                <h2>{pkg.name}</h2>
                                                <h4>{pkg.tagline}</h4>
                                                {pkg.small ? <small>{pkg.small}</small> : <></>}
                                                {pkg.features ? 
                                                    <ul className='automation-list'>
                                                        {pkg.features.map((feat, x) => {
                                                            return <li key={x}><LocalFlorist sx={{fontSize: 15}}/>{feat}</li>
                                                        })}
                                                    </ul> :
                                                    <></>
                                                }
                                                {pkg.description ? <p>{pkg.description}</p> : <></>}
                                            </div>
                                        </HashLink>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </section>
            </div>
        </>
    )
}
